<template>
  <div class="ant-hx-table">
    <div class="addBox">
      <a-button type="primary" @click="addClass">添加一级类目</a-button>
    </div>
    <div class="contentShowBox m-t1">
      <div class="ant">
        <a-space>
          <a-popover overlayClassName="ant-hx-table-column-setting-overlay" placement="bottom" trigger="click" arrow-point-at-center :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode
              }
            ">
            <template #content>
              <div class="ant-hx-table-column-setting-list">
                <a-checkbox-group v-model:value="checkColumns" @change="changeCheckColumns">
                  <div v-for="column in stateColumns" :key="column.dataIndex">
                    <a-checkbox :value="column.dataIndex">{{
                      column.title
                    }}</a-checkbox>
                  </div>
                </a-checkbox-group>
              </div>
            </template>
            <template #title>
              <div class="flex ju-between">
                <span>{{ $t('columnTitle') }}</span>
                <a @click="columnReset">{{ $t('columnReset') }}</a>
              </div>
            </template>
            <a-button>
              <ControlOutlined :rotate="90" />列展示
            </a-button>
          </a-popover>
          <a-button @click="refresh">
            <RedoOutlined :rotate="240" />刷新
          </a-button>
        </a-space>
      </div>
      <div class="m-t1">
        <a-table :columns="columns" :pagination="false" :expandedRowKeys="expandedRowKeys" :data-source="dataList" @expand="expand" :loading="searchLoading" :rowKey="
            (record, index) => {
              return record.id
            }
          ">
          <!-- 是否隐藏 -->
          <template #show="{ record }">
            <a-popconfirm ref="popRef" ok-text="确定" cancel-text="取消" @confirm="confirm(record)" overlayClassName="LY" :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode
                }
              ">
              <template #icon>
                <div class="titleTips flex ju-between al-center">
                  <span>提示</span>
                  <!-- <CloseOutlined @click="add" /> -->
                </div>
              </template>
              <template #title>
                <div v-if="record.isEnable.value == 1">确认要隐藏吗？</div>
                <div v-else>确认要显示吗？</div>
              </template>
              <a-switch :checkedValue="0" :unCheckedValue="1" size="small" :checked="record.isEnable.value" />
            </a-popconfirm>
          </template>
          <!-- 操作 -->
          <template #add="{ record }">
            <div class="flex ju-between" style="width: 220px; color: #1890ff">
              <span class="cur-p" v-if="record.level == 1" @click="subclassification(record,index)">添加子分类</span>
              <!-- <span v-if="record.level == 2" style="opacity: 0">添加子分类</span> -->
              <span class="cur-p" @click="edit(record)">编辑</span>
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <!-- 添加一级类目 -->
    <div>
      <repModal :visible="visible" width="23%" @getVal="getVal" @cancel="visible = false" :fromList="fromList" title="添加一级类目"></repModal>
    </div>
    <!-- 添加二级类目 -->
    <repModal :visible="visible2" width="23%" @getVal="getVal2" @cancel="visible2 = false" :fromList="fromList2" title="添加二级类目"></repModal>
    <!-- 弹出层 -->
  </div>
</template>

<script setup>
import {
  RedoOutlined,
  ControlOutlined,
  CloseOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { oaList, oaAdd, oaHide, oaEdit } from '@/api/repository/repository'
import { onMounted, ref } from 'vue'
import repModal from '../components/repModal.vue'
import { IfNull } from '@/utils/util'
import { useRoute } from 'vue-router'
const currentRoute = useRoute()
const expandedRowKeys = ref([])//记录展开的id可控制全部收起
const searchLoading = ref(false)
const visible = ref(false) //新增一级类目变量
const visible2 = ref(false) //新增二级类目变量
const addID = ref(null) //记录的增加的id
const editID = ref(null) //记录编辑的id
const isEdit = ref(false) //记录是编辑还是新增
const fromList = ref([
  //一级表单数据
  {
    label: '一级类目名称',
    name: 'name',
    type: 'input',
    placeholder: '',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  }
])
const fromList2 = ref([
  //二级表单数据
  {
    label: '二级类目名称',
    name: 'name',
    type: 'input',
    placeholder: '',
    value: null,
    prop: 'name',
    width: '100%',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  }
])
const columns = ref([
  {
    title: '项目名称',
    dataIndex: 'name',
    key: 'name',
    width: '18%'
  },
  {
    title: '添加人',
    dataIndex: 'operator',
    key: 'operator',
    width: '15%'
  },
  {
    title: '添加时间',
    dataIndex: 'createTime',
    width: '18%',
    key: 'createTime'
  },
  {
    title: '是否隐藏',
    dataIndex: 'show',
    width: '20%',
    key: 'show',
    slots: {
      customRender: 'show'
    }
  },
  {
    title: '操作',
    dataIndex: 'add',
    key: 'add',
    slots: {
      customRender: 'add'
    }
  }
])
const checkColumns = ref(
  columns.value.map((column) => {
    return column.dataIndex
  })
)
const stateColumns = JSON.parse(JSON.stringify(columns.value))
const dataList = ref([]) //表格数据
//编辑
const edit = (item) => {
  isEdit.value = true
  if (item.level == 1) {
    fromList.value[0].value = item.name
    visible.value = true
    editID.value = item.id
  }
  if (item.level == 2) {
    fromList2.value[0].value = item.name
    visible2.value = true
    editID.value = item.id
  }
}
//是否隐藏
const confirm = (item) => {
  oaHide({
    id: item.id,
    isEnable: {
      label: '',
      value: item.isEnable.value == 0 ? 1 : 0
    }
  }).then((res) => {
    if (res.code == 10000) {
      message.success(res.msg)
      if (item.isEnable.value == 1) {
        item.isEnable.value = 0
      } else {
        item.isEnable.value = 1
      }
    }
  })
}

//展开图标
const expandId = ref('')//记录展开哪一项的id
const expand = (item, val) => {
  if (item) {
        expandedRowKeys.value.push(val.id)
        expandId.value = val.id
        if (val.children.length !== 0) return
        oaList({
          superId: val.id
        }).then((res) => {
          if (res.code !== 10000) return
          dataList.value.forEach((opt) => {
            if (opt.id == val.id) {
              val.children = res.data
            }
          })
        })
      } else {
        let  Keys =   expandedRowKeys.value.filter(RowKey => RowKey !== val.id)
        expandedRowKeys.value = Keys
      }
}
//添加子分类
const subclassification = (item) => {
  isEdit.value = false
  addID.value = item.id
  visible2.value = true
  fromList2.value[0].value = null
}
//添加类目
const addClass = () => {
  fromList.value[0].value = null
  isEdit.value = false
  visible.value = !visible.value
}

// 新增、编辑一级类目
const getVal = (val) => {
  if (!IfNull(val.name)) {
    message.warn('请输入一级类目名称')
    return
  }
  if (isEdit.value) {
    setOaEdit({
      id: editID.value,
      name: val.name
    })
    return
  }
  newlyFun({
    name: val.name
  })
}

// 新增、编辑二级类目同一个请求传了id就表示是二级菜单
const getVal2 = (val) => {
  if (!IfNull(val.name)) {
    message.warn('请输入二级类目名称')
    return
  }
  if (isEdit.value) {
    setOaEdit(
      {
        id: editID.value,
        name: val.name
      },
      expandId.value
    )
    return
  }
  newlyFun(
    {
      name: val.name,
      superId: addID.value
    },
    addID.value
  )
}
//编辑请求
const setOaEdit = (val, id = '') => {
  oaEdit(val).then((res) => {
    if (res.code == 10000) {
      message.success(res.msg)
      visible.value = false
      visible2.value = false
      if(id){
       disposeData(id)
       return
     }
     expandedRowKeys.value = []
      getData()
    }
  })
}
//新增请求
const newlyFun = (val, id = '') => {
  oaAdd(val)
    .then((res) => {
      if (res.code == 10000) {
        message.success(res.msg)
        visible.value = false
        visible2.value = false
        if(id){
        disposeData(id)
        return
       }
       expandedRowKeys.value = []
        getData()
      }
    })
    .catch((err) => {
      message.error(err.msg)
    })
}
//获取二级数据
const disposeData = (id) => {
  oaList({
    superId: id
  }).then((res) => {
    if (res.code !== 10000) return
    dataList.value.forEach((opt) => {
      if (opt.id == id) {
        opt.children = res.data
      }
    })
  })
}
//刷新
const refresh = () => {
  expandedRowKeys.value = []
  getData()

}
//获取数据
const getData = () => {
  searchLoading.value = true
  oaList({
    superId: 0
  }).then((res) => {
    if (res.code !== 10000) return
    res.data.forEach((opt) => {
      opt.children = []
    })
    dataList.value = res.data
    searchLoading.value = false
  })
}
// 重置
const columnReset = () => {
  checkColumns.value = stateColumns.map((column) => {
    return column.dataIndex
  })
  columns.value = stateColumns
  window.sessionStorage.setItem(currentRoute.name, JSON.stringify(columns.value))
}
const changeCheckColumns = (checkItem) => {
  columns.value = stateColumns.filter((column) =>
    checkItem.includes(column.dataIndex)
  )
  window.localStorage.setItem(currentRoute.name, JSON.stringify(columns.value))
}
onMounted(() => {
  getData()
  if (window.localStorage.getItem(currentRoute.name)) {
        let list = JSON.parse(window.localStorage.getItem(currentRoute.name))
        columns.value = list
        checkColumns.value= list.map(column => { return column.dataIndex })
      }
})
</script>

<style lang="less" scoped>
.addBox {
  padding: 20px 10px;
  background: #fff;
}

.contentShowBox {
  padding: 20px 10px;
  background: #fff;

  .ant {
    :deep(.ant-btn) {
      background-color: #f0f2f5;
      border: none;
    }

    :deep(.ant-btn:hover) {
      color: #fff;
      border-color: #172b4d;
      background-color: #172b4d;
    }
  }
}

.titleTips {
  min-width: 300px;
  height: 40px;
  border-bottom: 1px solid #eeeeee;
  padding: 0 20px;
}

.showTips {
  margin: 10px 0;
  color: red;
}

:deep(.LY) {
  .ant-popover-inner-content {
    padding: 0 0 10px 0;
  }

  .ant-popover-buttons {
    margin-right: 10px;
  }
}

:deep(.ant-popover-inner-content) {
  padding: 12px 15px !important;
}
</style>
